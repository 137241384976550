/* Generated file. Please read https://github.com/jobteaser/next-jobteaser/blob/master/README.md#i18n. */
import type { I18nLocale, I18nNamespace } from "./types"

export const AVAILABLE_LOCALES: I18nLocale[] = [
  "ca",
  "cs",
  "da",
  "de",
  "en",
  "es",
  "fi",
  "fr",
  "gl",
  "it",
  "nl",
  "no",
  "pl",
  "pt",
  "sv",
]

export const AVAILABLE_NAMESPACES: I18nNamespace[] = [
  "about",
  "about_pages_fo",
  "account_setups_page",
  "action",
  "advices",
  "ai_cover_letter",
  "appoinments",
  "appointments",
  "backend",
  "bo_dashboard",
  "candidates",
  "career_explorer_bo",
  "career_explorer_fo",
  "communication",
  "community",
  "companies",
  "companiesGroupsCollective",
  "companiesGroupsCollectives",
  "companies_client",
  "companies_search",
  "company_credits",
  "corporate",
  "corporate_blog",
  "corporate_form",
  "corporate_schools_network",
  "courses",
  "crawler_bo",
  "cs_highlight",
  "cs_highlight_bo",
  "csat",
  "dashboard",
  "dashboard_fo",
  "dedicated_homepages",
  "enrolment",
  "event_details",
  "events",
  "events_bo",
  "events_fo",
  "events_promote_bo",
  "fraud_detector",
  "google_places",
  "handbook",
  "header",
  "homepage",
  "job_ad_detail",
  "job_ads_ai_bo",
  "job_ads_bo",
  "job_ads_fo",
  "job_alert",
  "job_offers",
  "job_offers_bo",
  "job_roles",
  "job_roles_bo",
  "job_search_prefiltered",
  "layout_error_404",
  "layout_landing_message",
  "login_modal",
  "meta",
  "module_footer_fo",
  "module_header_fo",
  "onboarding",
  "opportunities",
  "preprovisioning",
  "profile",
  "profile_edit_page",
  "profile_parameters_page",
  "profile_password_page",
  "profile_signup_page",
  "rawler_bo",
  "schools",
  "schools_bo",
  "shared_a11y",
  "shared_action",
  "shared_autocomplete",
  "shared_business_types",
  "shared_cdn_notification",
  "shared_company_categories",
  "shared_configure_feedback",
  "shared_contract_durations",
  "shared_contract_type",
  "shared_contract_types",
  "shared_corporate_menu_personas",
  "shared_countries",
  "shared_creation_source",
  "shared_date",
  "shared_date_time",
  "shared_disabled_locale_banner",
  "shared_download_the_app",
  "shared_error",
  "shared_events",
  "shared_experience_modal",
  "shared_experience_type",
  "shared_feedback",
  "shared_file_size_abbreviation",
  "shared_footer",
  "shared_form",
  "shared_format",
  "shared_freya",
  "shared_gender",
  "shared_header",
  "shared_job_ads_section",
  "shared_label",
  "shared_language_proficiencies",
  "shared_languages",
  "shared_layout_bo",
  "shared_modal",
  "shared_months",
  "shared_not_found",
  "shared_notification",
  "shared_onboarding-success-modal",
  "shared_phone_number",
  "shared_remote_types",
  "shared_resume_ai_validation",
  "shared_score",
  "shared_searchbar",
  "shared_select_input",
  "shared_server",
  "shared_shortlist_funnel",
  "shared_study_levels",
  "shared_talents",
  "shared_testimonial_modal",
  "shared_time",
  "shared_upload",
  "shared_work_experiences",
  "shortlists",
  "statistics_bo",
  "talent_connect",
  "targeted_communication",
  "terms_and_conditions",
  "test",
  "test-placeholder-to-remove",
  "transactional_pages",
  "uni_talents_bo",
]
