import { AVAILABLE_LOCALES } from "@/modules/i18n/constants"

export function isHome(pathname: string): boolean {
  return AVAILABLE_LOCALES.some(locale => [`/${locale}`, `/${locale}/`].includes(pathname))
}

export const isChildPage = (pathname: string): boolean => {
  return pathname.split("/").length > 3
}

export const isFeaturePage = (pathname: string, featurePathname: string, exact: boolean = true): boolean => {
  const [_empty, _locale, ...others] = pathname.split("/")
  const cleanFeaturePathname = featurePathname.startsWith("/") ? featurePathname.substring(1) : featurePathname

  if (!cleanFeaturePathname || cleanFeaturePathname === "/") {
    throw Error("Missing feature path name.")
  }

  if (cleanFeaturePathname.includes("?")) {
    throw Error("Path name should not include search params.")
  }

  if (exact) {
    return others.join("/") === cleanFeaturePathname
  }

  return others.join("/").startsWith(cleanFeaturePathname)
}
