"use client"

import type { ButtonHTMLAttributes, FunctionComponent, PropsWithChildren } from "react"

import { clsx } from "clsx"
import { usePathname } from "next/navigation"

import { isLinkActive } from "@/modules/headerFooterFO/Header/helpers/isLinkActive"

import dropdownMenuStyles from "@/modules/headerFooterFO/Header/styles/DropdownMenu.module.css"

type DropdownUserButtonProps = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    activeHrefs: string[]
    strictActiveCheck?: boolean
  }
>

export const DropdownUserButton: FunctionComponent<DropdownUserButtonProps> = ({
  activeHrefs,
  children,
  strictActiveCheck,
  ...props
}) => {
  const pathname = usePathname()

  return (
    <button
      {...props}
      className={clsx(dropdownMenuStyles["hdr-DropdownMenu-link"], {
        [dropdownMenuStyles["hdr-DropdownMenu-link--active"]]: activeHrefs.some(activeHref =>
          isLinkActive(pathname, activeHref, strictActiveCheck)
        ),
      })}
      type="button"
    >
      {children}
    </button>
  )
}
