import type { FunctionComponent } from "react"

import { clsx } from "clsx"

import styles from "./Icon.module.css"

const iconPaths = {
  AfterArrow: (
    <path
      fillRule="evenodd"
      d="M14.293 7.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L16.586 13H5a1 1 0 1 1 0-2h11.586l-2.293-2.293a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  ),
  ArrowDown: (
    <path
      fillRule="evenodd"
      d="M5.293 8.293a1 1 0 0 1 1.414 0L12 13.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  ),
  BurgerMenu: (
    <path
      fillRule="evenodd"
      d="M3 6a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm0 6a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm0 6a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  ),
  Cross: (
    <path
      fillRule="evenodd"
      d="M5.152 5.151a1.2 1.2 0 0 1 1.697 0L12 10.303l5.151-5.152A1.2 1.2 0 0 1 18.85 6.85L13.697 12l5.152 5.151a1.2 1.2 0 1 1-1.698 1.698L12 13.697l-5.151 5.152a1.2 1.2 0 0 1-1.697-1.698L10.303 12 5.152 6.849a1.2 1.2 0 0 1 0-1.698Z"
      clipRule="evenodd"
    />
  ),
  Profile: (
    <path
      fillRule="evenodd"
      d="M8.173 2.761a10 10 0 1 1 7.654 18.478A10 10 0 0 1 8.173 2.761Zm9.942 14.397a7.997 7.997 0 0 0-.458-10.815A8 8 0 0 0 5.885 17.158 4.999 4.999 0 0 1 10 15m-2.618 3.533A3 3 0 0 1 10 17h4a3 3 0 0 1 2.618 1.533 8.005 8.005 0 0 1-7.68.858 7.998 7.998 0 0 1-1.556-.858Zm10.733-1.375A5 5 0 0 0 14 15h-4m-.828-7.828a4 4 0 1 1 5.656 5.656 4 4 0 0 1-5.656-5.656ZM12 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      clipRule="evenodd"
    />
  ),
}

type IconProps = {
  className?: string
  isBig?: boolean
  name: keyof typeof iconPaths
}

export const Icon: FunctionComponent<IconProps> = ({ name, isBig, className }) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(styles["hdr-Icon"], { [styles["hdr-Icon--isBig"]]: isBig }, className)}
  >
    {iconPaths[name]}
  </svg>
)
