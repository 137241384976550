"use client"

import type { FunctionComponent, HTMLAttributes, PropsWithChildren } from "react"

import { clsx } from "clsx"
import { usePathname } from "next/navigation"

import { isFeaturePage, isHome } from "@/modules/routing/helpers/isPage"

import styles from "./Header.module.css"

type InteractiveAppHeaderProps = PropsWithChildren<{
  isBannerVisible?: boolean
  isLogged?: boolean
  isCareerCenter?: boolean
}> &
  HTMLAttributes<HTMLDivElement>

export const InteractiveAppHeader: FunctionComponent<InteractiveAppHeaderProps> = ({
  children,
  className,
  isBannerVisible,
  isLogged,
  isCareerCenter,
}) => {
  const pathname = usePathname()
  const isHomepage = isHome(pathname)
  const isAnonymous =
    !isLogged &&
    !isCareerCenter &&
    !(
      isFeaturePage(pathname, "profiles/new") ||
      isFeaturePage(pathname, "users/confirmation/new") ||
      isFeaturePage(pathname, "account_setups/new")
    )

  return (
    <header
      className={clsx(className, {
        [styles.main__fixed]: isHomepage && !isBannerVisible,
        [styles["header--anonymousUser"]]: isAnonymous,
      })}
      role="banner"
    >
      {children}
    </header>
  )
}
