"use client"

import type { AnchorHTMLAttributes, FunctionComponent, MouseEventHandler, PropsWithChildren } from "react"

import { clsx } from "clsx"
import { usePathname } from "next/navigation"

import { isLinkActive } from "@/modules/headerFooterFO/Header/helpers/isLinkActive"
import { Link } from "@/modules/routing/components/Link"

import styles from "./Nav.module.css"

type NavLinkProps = PropsWithChildren<
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href"> & {
    activeHrefs?: string[]
    href: string
    useNextLink?: boolean
  }
>

export const NavLink: FunctionComponent<NavLinkProps> = ({
  activeHrefs,
  children,
  href,
  useNextLink = true,
  ...props
}) => {
  const pathname = usePathname()
  const Component = useNextLink ? Link : "a"

  const handleClick: MouseEventHandler<HTMLAnchorElement> = () => {
    document.body.classList.remove("app-Body--noScroll")
    document.querySelector(".js-Header-wrapper")?.classList.remove("nav--open")
    document.querySelector(".js-Header-menuLink")?.classList.remove("dropdown--open")
  }

  return (
    <Component
      {...props}
      className={clsx(styles["app-Nav__link"], {
        [styles["app-Nav__link--active"]]: activeHrefs
          ? activeHrefs.some(activeHref => isLinkActive(pathname, activeHref))
          : isLinkActive(pathname, href),
      })}
      href={href}
      onClick={handleClick}
    >
      {children}
    </Component>
  )
}
