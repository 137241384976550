"use client"

import type { FunctionComponent, HTMLAttributes, PropsWithChildren } from "react"

import { usePathname } from "next/navigation"

import { isChildPage, isFeaturePage } from "@/modules/routing/helpers/isPage"

type InteractiveAppLocalesDropdownProps = PropsWithChildren<{
  from: "header" | "footer"
  isLogged: boolean
}> &
  HTMLAttributes<HTMLDivElement>

export const InteractiveAppLocalesDropdown: FunctionComponent<InteractiveAppLocalesDropdownProps> = ({
  children,
  className,
  from,
  isLogged,
}) => {
  const pathname = usePathname()

  if (
    from === "header" &&
    (isLogged ||
      (isChildPage(pathname) &&
        !isFeaturePage(pathname, "profiles/new") &&
        !isFeaturePage(pathname, "users/confirmation/new") &&
        !isFeaturePage(pathname, "account_setups/new")) ||
      isFeaturePage(pathname, "corporate", false))
  ) {
    return null
  }

  return <div className={className}>{children}</div>
}
