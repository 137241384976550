"use client"

import type { FunctionComponent, HTMLAttributes, PropsWithChildren } from "react"

import { usePathname } from "next/navigation"

import { isFeaturePage } from "@/modules/routing/helpers/isPage"

type InteractiveAppPersonasProps = PropsWithChildren & HTMLAttributes<HTMLElement>

export const InteractiveAppPersonas: FunctionComponent<InteractiveAppPersonasProps> = ({ children, className }) => {
  const pathname = usePathname()

  if (
    isFeaturePage(pathname, "profiles/new") ||
    isFeaturePage(pathname, "users/confirmation/new") ||
    isFeaturePage(pathname, "account_setups/new")
  ) {
    return null
  }

  return <div className={className}>{children}</div>
}
