"use client"

import type { FunctionComponent, MouseEventHandler, PropsWithChildren } from "react"

import { clsx } from "clsx"
import { useRef } from "react"

import { Icon } from "@/modules/headerFooterFO/Header/components/Icon/Icon"

import dropdownMenuStyles from "./styles/DropdownMenu.module.css"

type DropdownButtonProps = PropsWithChildren<{
  ariaLabel: string
}>

export const DropdownButton: FunctionComponent<DropdownButtonProps> = ({ ariaLabel }) => {
  const ref = useRef<HTMLButtonElement>(null)

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (!ref.current) {
      return
    }

    ref.current.classList.toggle("dropdown--open")
    document.body.classList.toggle("app-Body--noScroll")
    document.querySelector(".js-Header-wrapper")?.classList.toggle("nav--open")
  }

  return (
    <button
      aria-label={ariaLabel}
      className={clsx(
        dropdownMenuStyles["hdr-DropdownMenu-link"],
        dropdownMenuStyles["hdr-DropdownMenu-submenu-trigger"],
        "js-Header-menuLink"
      )}
      onClick={handleClick}
      ref={ref}
      type="button"
    >
      <Icon className={dropdownMenuStyles["hdr-DropdownMenu-submenu-trigger___icon__cross"]} name="Cross" isBig />
      <Icon className={dropdownMenuStyles["hdr-DropdownMenu-submenu-trigger___icon__menu"]} name="BurgerMenu" isBig />
    </button>
  )
}
